import React from "react";
import Slider from "react-slick";
import arrowLeft from "@images/global/arrow-left.png";
import arrowRight from "@images/global/arrow-right.png";
import "./style.scss";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  nextArrow: <SampleNextArrow />,
  prevArrow: <SamplePrevArrow />,
};

const style = { marginBottom: "-15px" };
export default function SimpleSlider({ children }) {
  return (
    <Slider {...settings} style={style}>
      {children}
    </Slider>
  );
}

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={arrowRight}
      className={`slick-arrows slick-arrows--right ${className}`}
      style={{ ...style }}
      onClick={onClick}
      alt="arrow-icon"
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={arrowLeft}
      alt="icon-arrow"
      className={`slick-arrows slick-arrows--left ${className}`}
      style={{ ...style }}
      onClick={onClick}
    />
  );
}
