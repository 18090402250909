import React from "react";
import btnBg2 from "@images/global/btn-bg-02.png";
import "./style.scss";

const btnStyle = {
  backgroundImage: `url(${btnBg2})`,
  backgroundSize: "100% 100%",
  padding: "30px 40px",
  color: "#ffffff",
  fontWeight: 700,
  cursor: "pointer",
};

const textStyle = {
  letterSpacing: "3px",
  padding: "0 15px",
  minWidth: "150px",
  textAlign: "center",
};

const BtnDark = ({ component = "div", style, onClick, children, ...props }) => {
  if (component === "a") {
    return (
      <a
        className="btn-dark"
        href={process.env.REACT_APP_TRY_GAME_FORM}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          ...btnStyle,
          ...style,
        }}
        {...props}>
        <div style={textStyle}>{children}</div>
      </a>
    );
  }

  return (
    <div
      onClick={onClick}
      style={{
        ...btnStyle,
        ...style,
      }}
      className="btn-dark">
      <div style={textStyle}>{children}</div>
    </div>
  );
};

export default BtnDark;
