import React from "react";
import { Link } from "gatsby";
import { useIntl } from "gatsby-plugin-intl";
import _truncate from "lodash.truncate";
import Title from "@components/Title";
import Slider from "@components/Slider";
import Panel from "@components/Panel";
import ScrollToTopBtn from "@components/ScrollToTopBtn";
import useNewsList from "@hooks/useNewsList";
import { SLIDER_PANEL_WIDTH, SLIDER_PANEL_MAX_WIDTH } from "@models";
import sitemap from "@models/sitemap.json";
import btnBg from "@images/global/btn-bg-01.png";

const SHOW_LATEST_NEWS_NUM = 5;

const NewsSlider = ({ page }) => {
  const { locale, formatMessage } = useIntl();
  const { newsList } = useNewsList();
  return (
    <section
      className={`news-section ${page === "home" ? "home" : ""}`}
      style={{
        paddingTop: "40px",
        paddingBottom: "85px",
        backgroundSize: "auto 100%",
      }}>
      {page === "home" && <ScrollToTopBtn containerClass={".home"} />}
      <Title page={page === "home" ? "news" : "indexNews"} locale={locale} />
      <div
        style={{
          width: SLIDER_PANEL_WIDTH,
          maxWidth: SLIDER_PANEL_MAX_WIDTH,
        }}>
        <Slider>
          {(newsList.length < SHOW_LATEST_NEWS_NUM
            ? newsList
            : newsList.slice(0, SHOW_LATEST_NEWS_NUM + 1)
          ).map((news, idx) => {
            const {
              context: {
                frontmatter: { date, title, img, summary },
                internal: { content },
              },
            } = news;
            const formatted = summary
              ? summary.replace(/(\r\n|\r|\n)/g, "<br>")
              : _truncate(content.replace(/(?:\r\n|\r|\n|#)/g, " "), {
                  length: 40,
                  separator: /,?\s+/,
                });
            return (
              <Panel key={idx}>
                <Panel.Grid>
                  <div>
                    <img src={`${process.env.GATSBY_PREFIX}${img}`} style={{ width: "100%" }} />
                  </div>
                  <div>
                    <Panel.Title>{title}</Panel.Title>
                    <Panel.Content>{new Date(date).toLocaleDateString()}</Panel.Content>
                    <Panel.Content>
                      <div dangerouslySetInnerHTML={{ __html: formatted }} />
                    </Panel.Content>
                  </div>
                </Panel.Grid>
              </Panel>
            );
          })}
        </Slider>
      </div>
      <Link to={`/${locale}${sitemap.news.path}`}>
        <div
          style={{
            backgroundImage: `url(${btnBg})`,
            backgroundSize: "100% 100%",
            padding: "30px 70px",
            color: "#919191",
            fontWeight: 700,
            marginTop: "15px",
          }}>
          {formatMessage({ id: "home-show-more" })}
        </div>
      </Link>
    </section>
  );
};

export default NewsSlider;
