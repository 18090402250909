import React, { useState, useEffect } from "react";
import btnGoTop from "@images/global/btn-go-top.png";

const ScrollToTopBtn = ({ containerClass }) => {
  const [show, setShow] = useState(false);

  // the scroll event should bind on containers
  useEffect(() => {
    const target_element =
      document.getElementsByClassName(containerClass)[0] ||
      (typeof window !== "undefined" ? window : null);

    const handleScroll = () => {
      const t = 400;
      if (target_element) {
        if (target_element.scrollTop >= t || target_element.pageYOffset >= t) {
          setShow(true);
        } else if (target_element.scrollTop < t || target_element.pageYOffset < t) {
          setShow(false);
        }
      }
    };

    if (target_element) target_element.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      if (target_element) target_element.removeEventListener("scroll", handleScroll);
    };
  }, [containerClass]);

  return (
    <img
      src={btnGoTop}
      alt="scroll-to-top-btn"
      style={{
        display: show ? "block" : "none",
        position: "fixed",
        bottom: "20px",
        right: "10px",
        width: "50px",
        height: "50px",
        cursor: "pointer",
      }}
      onClick={() => {
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }}
    />
  );
};

export default ScrollToTopBtn;
